<svelte:options tag="tabmodule-module"/>

<script>
    export let _tabobjects = tabobjects;
    export let textcolor;

    _tabobjects[0].active = true; // To make first tab always active

    function handleClick(event) {
        const tabNumber = event.currentTarget.dataset.tab;

        const tabs = event.currentTarget.parentElement.querySelectorAll(".tabmodule__tablist__tab");
        const tabpanels = event.currentTarget.parentElement.nextElementSibling.querySelectorAll(".tabmodule__tabpanel");

        tabs.forEach((tab) => {
          tab.classList.remove("active");
        });
        tabpanels.forEach((panel) => {
          panel.classList.remove("active");
        });

        event.currentTarget.classList.add("active");
        const activePanel = event.currentTarget.parentElement.nextElementSibling.querySelector(`.tabmodule__tabpanel[data-tab="${tabNumber}"]`);
        activePanel.classList.add("active");
    }

</script>

<div class="tabmodule grid grid-cols-1 md:grid-cols-4 color-{textcolor}">
  <div class="tabmodule__tablist col-span-1">
    {#each _tabobjects as tab}
      <button class="tabmodule__tablist__tab btn {tab.active ? 'active' : ''}" on:click={handleClick} data-tab={tab.id}>{tab.title}</button>
    {/each}
  </div>
  <div class="tabmodule__tabpanel-container col-span-1 md:col-span-3 lg:col-span-3 xl:col-span-3">
    {#each _tabobjects as tab}
      <div class="tabmodule__tabpanel {tab.active ? 'active' : ''}" data-tab={tab.id}>
          <h2 class="tabmodule__tabpanel__title font-sans">{tab.title}</h2>
          {#if tab.intro !== ""}
              <div class="tabmodule__tabpanel__intro text--big text-body">
                  {@html tab.intro}
              </div>
          {/if}
          {#if tab.images[0].image !== ""}
              <div class="tabmodule__tabpanel__images grid gap-8 mb-16 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                  {#each tab.images as image}
                      <div>
                          <h3 class="title--sans">{image.image_title}</h3>
                          <img src="{image.image}" alt="{image.image_title}">
                      </div>
                  {/each}
              </div>
          {/if}
          {#if tab.body}
              <div class="tabmodule__tabpanel__body text-body">
                  {@html tab.body}
              </div>
          {/if}
      </div>
    {/each}
  </div>
</div>

<style>
    @import '/static/website/bundle.css';
    @import "https://kit.fontawesome.com/62abe87950.css";
</style>
